<template>
  <div class="pick-calendar" :class="{ bordered: bordered, manager: manager, skeleton: !computedLoaded }">
    <div class="calendar">
      <div class="alert alert-info font-sm mt-3 mb-0" v-if="!manager">
        <template>예약 가능한 일자를 클릭하여 선택해주세요.</template>
        <template v-if="selectableCnt !== $definitions.pickCalendar.unlimitedNum">(최대 {{ selectableCnt }}일)</template>
      </div>
      <div class="top clearfix">
        <div class="subject">
          <b>{{ title }}</b>
          <span class="font-xs">{{ state.display.year }}년</span>
          <span class="font-xs">{{ state.display.month + 1 }}월</span>
        </div>
        <div class="actions">
          <i class="prev fa fa-angle-left" aria-hidden="true" title="이전 달" @click="turn(-1)"></i>
          <i class="next fa fa-angle-right" aria-hidden="true" title="다음 달" @click="turn(1)"></i>
        </div>
      </div>
      <div class="header clearfix">
        <div v-for="(w, idx) in ['일', '월', '화', '수', '목', '금','토']" :key="idx">
          <div class="wrapper">
            <span>{{ w }}</span>
          </div>
        </div>
      </div>
      <div class="dates clearfix">
        <div :class="{within: d.within, active: d.active, selected: isSelected(d.value), selectable: selectableCnt, 'diff-month': d.value.getMonth() !== state.display.month}" v-for="(d, idx) in state.display.dates" :key="idx"
             @click="selectableCnt && d.active && toggle(d.value)">
          <div class="wrapper">
            <div class="text">
              <span class="month">{{ d.value.getMonth() + 1 }}/</span>
              <span>{{ d.text }}</span>
            </div>
            <div class="remain font-xs">
              <span v-if="manager">{{ d.paid }}개 <br class="d-block d-sm-none"/>완료</span>
              <span v-else-if="d.remain > 0">
                <template v-if="$definitions.pickCalendar.reservableCntPerDay === $definitions.pickCalendar.unlimitedNum">
                  예약 <br class="d-block d-sm-none"/>가능
                </template>
                <template v-else>{{ d.remain }}개 <br class="d-block d-sm-none"/>남음</template>
              </span>
              <span v-else>예약 <br class="d-block d-sm-none"/>불가</span>
            </div>
            <i class="fa fa-check-square" v-if="isSelected(d.value)"></i>
            <i class="fa fa-warning" v-if="manager && $definitions.pickCalendar.reservableCntPerDay && d.paid > $definitions.pickCalendar.reservableCntPerDay"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="info clearfix">
      <div class="left">
        <div>
          <i class="bg-success"></i>
          <span>예약 가능</span>
        </div>
        <div>
          <i class="bg-danger"></i>
          <span>예약 불가</span>
        </div>
        <div>
          <i class="gray"></i>
          <span>펀딩 기간 아님</span>
        </div>
      </div>
      <div class="right" v-if="state.selectedDates.length">
        <span>({{ state.selectedDates.length }}개 선택)</span>
      </div>
    </div>
    <div class="select" v-if="state.selectedDates.length">
      <ul class="tight">
        <li v-for="(d, idx) in state.selectedDates" :key="idx">
          <span class="badge badge-secondary">선택</span>
          <span>{{ $lib.getDateFormat(d, "yyyy년 MM월 dd일") }}</span>
          <span class="cancel" @click="toggle(d)">취소</span>
        </li>
      </ul>
    </div>
    <div class="alert alert-warning font-sm mt-3" v-if="manager && state.warningMessage">
      <i class="fa fa-warning mr-2"></i>
      <span>{{ state.warningMessage }}</span>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import lib from "@/scripts/lib";
import http from "@/scripts/http";
import store from "@/scripts/store";
import definitions from "../scripts/definitions";

function Component(initialize) {
  this.name = "componentPickCalendar";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    projectSeq: String,
    rewardItemTitle: String,
    value: Array,
    title: String,
    bordered: Boolean,
    selectableCnt: Number,
    manager: Boolean,
    loaded: Boolean,
  },
  setup(props, {emit}) {
    const component = new Component(() => {
      const currDate = new Date();
      state.display.year = currDate.getFullYear();
      state.display.month = currDate.getMonth();
      draw();

      state.loaded = false;
      http.get(`/api/reward/projects/${props.projectSeq}/pick-calendar-reserved-dates`).then((res) => {
        state.loaded = true;

        const startDates = definitions.pickCalendar.startDate.split("-").map(d => window.Number(d));
        const endDates = definitions.pickCalendar.endDate.split("-").map(d => window.Number(d));

        state.display.year = startDates[0];
        state.display.month = startDates[1] - 1;

        state.minDate = new Date(startDates[0], startDates[1] - 1, startDates[2], 0, 0, 0, 0);
        state.maxDate = new Date(endDates[0], endDates[1] - 1, endDates[2], 0, 0, 0, 0);

        state.reservedDates = res.data.body;

        // 시작일이 2월 28일이므로 3월부터 출력하도록 함
        state.display.month += 1;
        draw();
      });
    });

    const state = reactive({
      loaded: false,
      reservedDates: [],
      selectedDates: [],
      display: {
        year: 0,
        month: 0,
        dates: []
      },
      maxDate: new window.Date(),
      minDate: new window.Date(),
      warningMessage: "",
    });

    const computedLoaded = computed(() => {
      return props.loaded && state.loaded;
    });

    const getFirstDayOfMonth = (d) => {
      const date = new Date(d.getTime());
      date.setDate(1);
      return date.getDay();
    };

    const getLastDayOfMonth = (d) => {
      const date = new Date(d.getTime());
      const lastDateOfMonth = getLastDateOfMonth(date);
      date.setDate(lastDateOfMonth);
      return date.getDay();
    };

    const getLastDateOfPrevMonth = (d) => {
      const date = new Date(d.getTime());
      date.setDate(1);
      date.setDate(date.getDate() - 1);
      return date.getDate();
    };

    const getLastDateOfMonth = (d) => {
      const date = new Date(d.getTime());
      date.setDate(1);
      date.setMonth(date.getMonth() + 1);
      date.setDate(date.getDate() - 1);
      return date.getDate();
    };

    const getIndex = (date) => {
      for (let i in state.selectedDates) {
        if (state.selectedDates[i].getFullYear() === date.getFullYear()
            && state.selectedDates[i].getMonth() === date.getMonth()
            && state.selectedDates[i].getDate() === date.getDate()
        ) {
          return window.Number(i);
        }
      }

      return -1;
    };

    const isSelected = (date) => {
      return getIndex(date) >= 0;
    };

    const toggle = (date) => {
      const idx = getIndex(date);

      if (idx >= 0) {
        state.selectedDates.splice(idx, 1);
      } else {
        if (state.selectedDates.length >= props.selectableCnt) {
          return store.commit("setSwingMessage", `예약 가능한 일자(${props.selectableCnt}일)를 모두 선택하셨습니다.`);
        }

        state.selectedDates.push(date);
      }

      state.selectedDates.sort((a, b) => a - b);
      emit("update:value", state.selectedDates.map(d => lib.getDateFormat(d, "yyyy-MM-dd")));
    };

    const draw = () => {
      const date = new Date(state.display.year, state.display.month, 1, 0, 0, 0, 0);
      const firstDayOfMonth = getFirstDayOfMonth(date);
      const lastDateOfPrevMonth = getLastDateOfPrevMonth(date);
      const lastDateOfMonth = getLastDateOfMonth(date);
      const lastDayOfMonth = getLastDayOfMonth(date);

      state.display.dates = [];

      for (let i = 0; i < firstDayOfMonth; i += 1) {
        const ctxDate = new Date(date.getTime());
        ctxDate.setDate(ctxDate.getDate() - i - 1);

        state.display.dates.unshift({
          value: ctxDate,
          text: lastDateOfPrevMonth - i,
          within: false,
          active: false,
          paid: 0,
          remain: 0,
        });
      }

      for (let i = 0; i < lastDateOfMonth; i += 1) {
        const ctxDate = new Date(date.getTime());
        ctxDate.setDate(ctxDate.getDate() + i);

        state.display.dates.push({
          value: ctxDate,
          text: i + 1,
          within: false,
          active: false,
          paid: 0,
          remain: 0,
        });
      }

      if (lastDayOfMonth < 7) {
        for (let i = 0; i < 7 - lastDayOfMonth - 1; i += 1) {
          const ctxDate = new Date(date.getTime());
          ctxDate.setDate(lastDateOfMonth);
          ctxDate.setDate(ctxDate.getDate() + i + 1);

          state.display.dates.push({
            value: ctxDate,
            text: i + 1,
            within: false,
            active: false,
            paid: 0,
            remain: 0,
          });
        }
      }

      for (let i in state.display.dates) {
        const date = lib.getDateFormat(state.display.dates[i].value, "yyyy-MM-dd");
        state.display.dates[i].within = state.display.dates[i].value >= state.minDate && state.display.dates[i].value <= state.maxDate;

        if (state.display.dates[i].within) {
          state.display.dates[i].paid = state.reservedDates[date] || 0;

          // 월, 화요일은 휴무 처리하되 2023년 1월 23, 24일은 예외
          // if ((state.display.dates[i].value.getFullYear() === 2023 && state.display.dates[i].value.getMonth() === 0 && [23, 24].includes(state.display.dates[i].value.getDate()))
          //     || ![1, 2].includes(state.display.dates[i].value.getDay())) {
          //   state.display.dates[i].remain = state.reservedDates[date] ? definitions.pickCalendar.reservableCntPerDay - state.reservedDates[date] : definitions.pickCalendar.reservableCntPerDay;
          // } else {
          //   state.display.dates[i].remain = 0;
          // }

          state.display.dates[i].remain = state.reservedDates[date] ? definitions.pickCalendar.reservableCntPerDay - state.reservedDates[date] : definitions.pickCalendar.reservableCntPerDay;

          // #890 DMZ 프로젝트 특정 기간 예약 불가하도록 (임시 처리, 삭제 예정)
          if (!props.manager) {
            const eachDate = state.display.dates[i].value.getFullYear() + "" + lib.getNumberWithPadding(state.display.dates[i].value.getMonth() + 1) + lib.getNumberWithPadding(state.display.dates[i].value.getDate());
            if (20240227 <= eachDate && eachDate <= 20240309) {
              state.display.dates[i].remain = 0;
            }
          }

          state.display.dates[i].active = state.display.dates[i].remain > 0;

          if (state.display.dates[i].remain < 0) {
            state.warningMessage = `예약 가능한 일자(${definitions.pickCalendar.reservableCntPerDay}일)를 초과한 내역이 있어 확인이 필요합니다.`;
          }
        }
      }
    };

    const turn = (num) => {
      state.display.month += num;

      if (state.display.month > 11) {
        state.display.year += 1;
        state.display.month = 0;
      } else if (state.display.month < 0) {
        state.display.year -= 1;
        state.display.month = 11;
      }

      draw();
    };

    return {component, state, computedLoaded, turn, draw, toggle, isSelected};
  }
});
</script>

<style lang="scss" scoped>
.pick-calendar {
  border-radius: $px4;

  .calendar {
    text-align: center;
    border-radius: $px4;
    width: 100%;
    padding: 0 $px15;
    z-index: 1;

    .top {
      border-radius: $px4;
      position: relative;
      text-align: left;
      padding: $px5 0;

      .subject {
        font-size: $px16;
        float: left;
        padding: $px10 $px5;

        b {
          margin-right: $px6;
        }

        span {
          margin-right: $px4;
        }
      }

      .actions {
        float: right;

        i {
          padding: $px10;
          cursor: pointer;
          opacity: 0.8;
          transition: opacity 0.18s;
          vertical-align: middle;
          margin-left: $px10;

          &.prev, &.next {
            font-size: $px24;
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .title {
      padding: $px10;

      a {
        float: right;
        font-size: 0.75rem;
        margin-top: $px-2;
        padding: $px2 $px5;
      }
    }

    .header, .dates {
      > div {
        font-size: $px14;
        float: left;
        width: calc(100% / 7 - 0.01px);

        > .wrapper {
          border-radius: $px2;

          > .text .month {
            display: none;
          }
        }

        &.diff-month {
          > .wrapper > .text .month {
            display: inline;
          }

          &:not(.within) > .wrapper {
            opacity: 0.5;
          }
        }
      }
    }

    .header {
      > div {
        padding: $px2;

        > .wrapper {
          border: $px1 solid #eee;
          background: $colorBackground;
          color: #767676;
          padding: $px15 0;
        }
      }
    }

    .dates {
      padding-bottom: $px10;

      > div {
        padding: $px2;
        position: relative;

        > .wrapper {
          padding: $px10 $px5;
          border: $px1 solid #eee;
          background: $colorBackground;
          width: 100%;
          height: $px64;
          transition: border-color 0.18s, background-color 0.18s;

          .remain {
            visibility: hidden;
            padding-top: $px3;
          }

          i {
            position: absolute;
            top: $px9;
            right: $px9;
          }
        }

        &.within {
          > .wrapper {
            .remain {
              visibility: visible;
            }
          }

          &:not(.active) {
            > .wrapper {
              background: #fdf1f2;
              border-color: #f5c6cb;;
              color: #721c24;
            }
          }

          &.active {
            > .wrapper {
              background: #fff;
              border-color: #c3e6cb;
              color: #155724;
            }

            &.selectable > .wrapper {
              cursor: pointer;

              &:hover {
                background: #ecfff1;
              }
            }

            &.selected > .wrapper {
              background: $colorPoint;
              color: #fff;

              &:hover {
                background: $colorPoint;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .info {
    padding: 0 $px20 $px15 $px20;

    > .left {
      float: left;

      > div {
        display: inline-block;
        margin-right: $px20;

        i, span {
          display: inline-block;
          vertical-align: middle;
        }

        i {
          width: $px10;
          height: $px10;

          &.gray {
            background: #eee;
            border: $px1 solid #aaa;
          }
        }

        span {
          font-size: $px14;
          margin-left: $px10;
        }
      }
    }

    > .right {
      float: right;
      font-size: $px14;
    }
  }

  .select {
    padding: 0 $px15 $px15 $px15;

    > ul > li {
      background: $colorBackground;
      margin-bottom: $px5;
      padding: $px10 $px15;
      border: $px1 solid $colorBorder;
      font-size: $px14;

      span {
        vertical-align: middle;

        &.badge {
          margin-right: $px7;
          border-radius: 0;
          padding: $px4;
        }

        &.cancel {
          cursor: pointer;
          float: right;
          opacity: 0.9;
          font-size: $px13;
          margin-top: $px1;

          &:hover {
            opacity: 1;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.bordered {
    border: $px1 solid $colorBorder;
  }

  &.manager {
    .calendar {
      padding: 0;
    }

    .info {
      padding: 0 $px5;
    }
  }

  &.skeleton {
    .calendar {
      .alert {
        @include skeleton;
      }

      .top {
        .subject {
          b, span {
            @include skeleton;
          }
        }

        .actions i {
          @include skeleton;
        }
      }

      .header > div > div > span {
        @include skeleton;
      }

      .dates > div > .wrapper {
        @include skeleton;
      }
    }

    .info {
      > .left > div {
        i, span {
          @include skeleton;
        }
      }

      > .right > span {
        @include skeleton;
      }
    }
  }

  @media(max-width: 991px) {
    .calendar {
      padding: 0 $px10;

      .dates {
        > div {
          > .wrapper {
            padding: $px5 $px3;
            height: $px76;
            overflow: hidden;

            i {
              top: $px1;
              right: $px1;
            }
          }
        }
      }
    }

    .info {
      padding: $px5 $px5 $px20 $px20;
    }
  }
}
</style>